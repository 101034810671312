@import "@blueprintjs/core/lib/css/blueprint.css";
@import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";


body {
  font-family: 'Abril Fatface', cursive;
  font-family: 'Climate Crisis', cursive;
}

.App {
  text-align: center;
}
a:hover {
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


img {
  border-radius: 4px;
}

.bp4-navbar-divider {
  border-left: 1px solid silver;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.container {
  margin-top: 10px;
}

.grid {
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.item {
  margin: 10px;
  max-width: 250px;
  min-width: 250px;
}

.bp4-button:not([class*=bp4-intent-]) {
  box-shadow: none;
  background-color: white;
}

.bp4-button:not([class*=bp4-intent-]):hover {
  box-shadow: none;
  background-color: white;
}

.menu-items div, .menu-items a {
  margin-left: 5px;
  margin-right: 5px;
}

.bp4-navbar {
  box-shadow: none;
  border-bottom: 0.5px solid silver;
  height: 75px;
  padding: 10px 15px;
}
.onHoverPanel {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  background-color: silver;
  padding: 50px 50px;
}
.img1 {
  background-image: url('https://thehousecannabis.ca/static/ec2cc83b5630cab0eb8e9ca65e1f8e63/08684/hero_hd_eebbc18ed0.avif');
}

.img2 {
  background-image: url('https://thehousecannabis.ca/static/063f1e2dd5cb8770dadfe88e5305fde6/b44c8/delivery_hd_3a79c30ade.avif');
}

.img3 {
  background-image: url('https://thehousecannabis.ca/static/71fe545981f7ce7fa82a6a8be6b35b8d/afd04/hero_specials_1147f530bb.avif');
}

.onHoverPanel:hover {
  box-shadow: 5px 5px 11px rgba(53, 52, 52, 0.2); 
  transition: 0.5s;
  cursor: pointer;
}

.title {
  font-size: 42px;
  color: rgb(45, 43, 44);
  margin-bottom: 25px;
  z-index: 2;
  width: 45%;
  font-weight: 700;
}
.header {
  font-size: 26px;
  color: rgb(45, 43, 44);
  margin-bottom: 15px;
  z-index: 2;
  width: 32.5%;
  font-weight: 500;
}
.smaller-header {
  font-size: 17px;
  color: rgb(45, 43, 44);
  margin-bottom: 15px;
  z-index: 2;
  width: 32.5%;
  font-weight: 500;
}

.smaller-header2 {
  font-size: 17px;
  color: rgb(45, 43, 44);
  z-index: 2;
  width: 100%;
  font-weight: 500;
}

.panel1 {
  min-height: 350px;
}

.panel2 {
  min-height: 720px;
}

.shortPanel {
  min-height: 350px;
}

.tallPanel {
  min-height: 720px;
}
.tallPanel2 {
  min-height: 710px;
}

.happy {
  background-image: url('https://thehousecannabis.ca/static/fc6d9d6dd958ccb752033a8098089bab/c6cd1/happy_resize_6f810886c4.avif');
}

.calm {
  background-image: url('https://thehousecannabis.ca/static/d19eefd9a53bf5f7232e5426ea8a0e9a/c6cd1/calm_resize_a3de15e265.avif');
}

.energetic {
  background-image: url('https://thehousecannabis.ca/static/01f246e94944c3d417446d0b2c5fd05c/64339/energetic_resize_e7478c3950.avif');
}

.sleepy {
  background-image: url('https://thehousecannabis.ca/static/557c1dbd58da105da307e732b6e887b9/c6cd1/sleepy_resize_8af5e9ca9c.avif');
}

.creative {
  background-image: url('https://thehousecannabis.ca/static/15595b8544a5e94738c15fb683cb7944/c6cd1/creative_resize_5c09c42e2e.avif');
}

.navbar-collapse {
  padding: 0 20px;
}

.navbar .bp4-button, .navbar .bp4-button:hover {
  background-color: transparent;
}
.navbar-toggler {
  position: relative;
  right: 10px;
}
.badge .bp4-button .bp4-icon {
  color: white !important;
}

.badge .bp4-button .bp4-icon:hover {
  color: silver !important;
}

.rounded-pill {
  padding: 2px 5px 2px 15px;
}

.bottomSection {
  position: absolute;
  bottom: 65px;
  text-align: right;
}

.chakra-slide .chakra-modal__body {
  padding: 5px 0px 5px 5px;
}

.bp4-toast-container {
  position: fixed !important;
  padding: 95px 20px 20px;
  z-index: 999 !important;
}

:focus {
  outline: none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-container {
  max-height: 88%;
  overflow-y: auto;
  overflow-x: hidden;
}

.productCard {
  margin: 5px;
  min-height: 550px;
}

.addToCart .chakra-badge {
  position: relative;
  top: -1px;
  right: 3px;
  border-radius: 25px;
}

.showInCart { 
  border: 2px solid #38a169 !important;
  border-radius: 25px;
}

.saleBorder {
  border: 4px solid red !important;
  border-radius: 25px;
}

.newProduct {
  border: 4px solid blue !important;
  border-radius: 25px;
}

 
#coupon-checkbox input {
  width: 25px;
  height: 25px; 
}

#coupon-checkbox label {
  margin-left: 8px;
  margin-top: 4px;
  font-size: 14px;
}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .productCard {
      min-height: 460px;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .productCard {
      min-height: 460px;
    }
}

.slick-slider {
  width: 98%;
  margin: 0 auto;
}

.chakra-input-custom {
  width: 100%;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  -webkit-padding-start: var(--chakra-space-2);
  padding-inline-start: var(--chakra-space-2);
  -webkit-padding-end: var(--chakra-space-2);
  padding-inline-end: var(--chakra-space-2);
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: inherit;
  background: inherit;
}

.chakra-input-custom-2 {
  width: 50%;
  text-align: center;
}

.slick-prev:before, .slick-next:before {
  color: black !important;
}

.landingPageCol, .landingPageCol2 {
  margin-top: 10px;
}

.view-report {
  display: inline-flex;
}

.view-report svg {
  position: relative;
  top: 5px;
  left: 6px;
}

.navbar-progressbar {
  width: 1000px;
  margin-left: 90px;
  height: 30px;
  font-size: 11px;
  font-weight: bold;
}

.onIconHoverSuccess:hover {
  transition: 0.5s;
  color: green;
} 

.onIconHoverDanger:hover {
  transition: 0.5s;
  color: red;
} 

.progress-bar {
  background-color: #6b9080;
}

.navbar-progressbar .progressbar {
  padding: 0px 10px;
}

.full-bg {
  margin: 0;
  padding: 0;
  height: 670px;
  background-image: url('./homepage-bg.jpeg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.text-overlay {
  position: absolute;
  top: 50%; /* Adjust the vertical position of the text */
  left: 50%; /* Adjust the horizontal position of the text */
  transform: translate(55%, -300%); /* Center the text both vertically and horizontally */
  color: black;
  text-align: left;
  /* Add other styles as needed */
}

/* Style the heading "Queen Mary" */
.landing-container h1 {
  font-size: 65px;
  font-weight: bold;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Add a subtle text shadow */
}

/* Style the paragraph "The Gold Standard in Cannabis, Est. 2016" */
.landing-container p {
  font-size: 24px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Add a subtle text shadow */
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 388px;
  height: 40px;
  padding: 10px 10px;
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  border: 1px solid;
  border-color: inherit;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 41px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
.backToTop button {
  position: fixed;
  bottom: 50px;
  right: 35px;
}

.recentlyBought {
  position: fixed;
  bottom: 85px;
  left: 25px;
  z-index: 999;
  min-width: 330px;
  max-width: 350px;
}

#shopSearchInput {
  position: relative;
  top: 3px;
  width: 45%;
  max-height: 31px;
}

@media only screen and (max-width: 600px) {
  .recentlyBought {
    min-width: 332px;
    max-width: 332px;
  }

  #shopSearchInput {
    width: 55%;
  }

  .backToTop button {
    bottom: 30px;
    right: 28px;
  }
  .navbar-progressbar {
    margin-left: 0px;
    width: 350px !important;
  }

  .text-overlay {
    position: absolute;
    top: 50%; /* Adjust the vertical position of the text */
    left: 50%; /* Adjust the horizontal position of the text */
    transform: translate(-5%, -250%); /* Center the text both vertically and horizontally */
    color: black;
    text-align: left;
    /* Add other styles as needed */
  }
  .content-container {
    max-height: 86%;
  }
  .title, .header {
    width: 100%;
  } 
  .panel2, .tallPanel {
    min-height: 350px;
  }
  .productCard {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #basic-navbar, .navbar-collapse {
    position: relative;
    top: 8px;
    background-color: white !important;
  }
  .adminNavbar .navbar-collapse {
    background-color:rgba(33,37,41, 1)!important;
  }

  .navbar-collapse {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .landingPageCol {
    margin-top: 10px;
  }
  .landingPageCol2 {
    margin-top: 10px;
  }
  .landingPageCol3 {
    margin-top: 10px;
  }
  .tallPanel2 {
    min-height: 350px;
  }
}

@media only screen and (max-width: 300px) {
  .content-container {
    max-height: 80%;
  }
}